import React, { useState, useEffect, useRef } from 'react';
import ChatTitle from './ChatTitle';
import MessageList from './MessageList';
import ChatForm from './ChatForm';
import BotonesChat from './BotonesChat'
import SubirMedia from './SubirMedia'
import '../../assets/styles/chat/ChatShell.scss';
import LinearProgress from '@mui/material/LinearProgress';
import Pusher from 'pusher-js'



const sleep = ms => new Promise(
    resolve => setTimeout(resolve, ms)
);
export default function ChatShell({ closeChat, openChat, setWillSound }) {
    const [formularioLlenado, setFormularioLlenado] = useState('Formulario')

    const [hayBotones, setHayBotones] = useState(false)
    const [botones, setBotones] = useState([])
    const [botonesDeFinalizacion, setBotonesDeFinalizacion] = useState([])
    const [messageSend, setMessageSend] = useState([])
    const [receiveMsg, setReceiveMsg] = useState(false)
    const [agentePersona, setAgentePersona] = useState(false)
    const [empezarTimer, setEmpezarTimer] = useState(false)
    const [interval, setIntervalo] = useState(null)
    const [pusherActive, setPusherActive] = useState(false)


    useEffect(() => {
        if (empezarTimer) {
            const intervaloFranco = setInterval(async () => {
                if (empezarTimer) {
                    var farmId = localStorage.getItem('farmId')
                    var urlFive9 = localStorage.getItem('urlFive9')
                    var chatId = localStorage.getItem('chatId')
                    var externalId = localStorage.getItem('externalId')
                    let jsonSendMessage = {
                        messageType: 'TEXT',
                        message: 'SI',
                        externalId: externalId
                    }
                    const requestSendMessage = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'farmId': `${farmId}`,
                            'Authorization': `Bearer-${chatId}`
                        },
                        body: JSON.stringify(jsonSendMessage)
                    }
                    var url = urlFive9 + '/' + chatId + '/messages'
                    try {
                        await fetch(url, requestSendMessage)
                            .then(responseSendMessage => responseSendMessage.json())
                            .then(dataSendMessage => {
                                if (dataSendMessage.five9ExceptionDetail.message === 'Conversation has been already terminated') {
                                    setFormularioLlenado('Formulario')
                                    localStorage.setItem('estadoChat', 'Formulario')
                                }
                            })
                    } catch (error7) {
                    }
                }
            }, 120000)
            localStorage.setItem('intervaloFranco', intervaloFranco)
            setIntervalo(intervaloFranco)
        } else {
            const getIntervalo = localStorage.getItem('intervaloFranco')
            if (getIntervalo !== null) {
                clearInterval(getIntervalo);
            }
        }
    }, [empezarTimer])
    useEffect(() => {
        if (localStorage.getItem("mensajes")) {
            if (JSON.parse(localStorage.getItem("mensajes")).length !== 0) {
                if (messageSend.length !== 0) {
                    localStorage.setItem('mensajes', JSON.stringify(messageSend))
                }
            } else {
                localStorage.setItem('mensajes', JSON.stringify(messageSend))
            }
        } else {
            localStorage.setItem('mensajes', JSON.stringify([]))
        }
    }, [messageSend])



    useEffect(() => {
        if (!localStorage.getItem("estadoChat")) {
            localStorage.setItem('estadoChat', 'Formulario')
            setFormularioLlenado('Formulario')
        }
    }, [formularioLlenado])

    useEffect(() => {
        if (openChat) {
            if (!pusherActive) {
                setMessageSend(JSON.parse(localStorage.getItem("mensajes")))
                setBotones(JSON.parse(localStorage.getItem("botones")))
                setBotonesDeFinalizacion(JSON.parse(localStorage.getItem("botonesFin")))
                setFormularioLlenado(localStorage.getItem("estadoChat"))
                if (localStorage.getItem("hayBotones") === 'true') {
                    setHayBotones(true)
                } else {
                    setHayBotones(false)
                }
                if (localStorage.getItem("agentePersona") === 'true') {
                    setAgentePersona(true)
                } else {
                    setAgentePersona(false)
                }
                if (localStorage.getItem("EmpezarTimer") === 'true') {
                    setEmpezarTimer(true)
                } else {
                    setEmpezarTimer(false)
                }
                var chatId = localStorage.getItem('chatId')
                if (chatId) {
                    var pusher = new Pusher('00dc67b9864017594a92', {
                        cluster: 'us2',
                        encrypted: true
                    });
                    const mychannel = chatId + '-my-channel'
                    var channel = pusher.subscribe(mychannel);
                    channel.bind('message', async function (datosChannel) {
                        var chatId = localStorage.getItem('chatId')
                        if (datosChannel.message.correlationId === chatId) {
                            if (datosChannel.message.text === 'En COLFONDOS nos importas tu, por eso pensando en tu seguridad hemos enviado un código de acceso seguro a tu número celular, por favor digítalo para acceder a nuestros servicios.' ||
                                datosChannel.message.text === 'El código ingresado no concuerda con el código enviado. Por favor inténtalo de nuevo.') {
                                setEmpezarTimer(true)
                                localStorage.setItem('EmpezarTimer', true)
                            } else {
                                setEmpezarTimer(false)
                                localStorage.removeItem('EmpezarTimer')
                            }
                            if (datosChannel.message.text === 'No agents are currently available') {
                            } else {
                                var urlFive9 = localStorage.getItem('urlFive9')
                                var farmId = localStorage.getItem('farmId')
                                var chatIdAcknowledge = localStorage.getItem('chatId')
                                const msg = datosChannel.message.messageId.toString()
                                if (msg.includes('agent')) {
                                    const jsonAcknowledge = {
                                        'messages': [
                                            {
                                                'type': 'DELIVERED',
                                                'messageId': datosChannel.message.messageId
                                            }
                                        ]

                                    }
                                    const requestAcknowledge = {
                                        method: 'PUT',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'farmId': `${farmId}`,
                                            'Authorization': `Bearer-${chatIdAcknowledge}`
                                        },
                                        body: JSON.stringify(jsonAcknowledge)
                                    }
                                    var urlRequestAcknowledge = urlFive9 + '/' + chatId + '/messages/acknowledge'
                                    try {
                                        await fetch(urlRequestAcknowledge, requestAcknowledge)
                                            .then(responseAcknowledge => responseAcknowledge.json())
                                            .then(dataSendAcknowledge => {
                                            }).catch(errormute => { const mute = errormute })

                                    } catch (error4) {
                                    }
                                }

                                setWillSound(true)
                                setFormularioLlenado('Chat')
                                localStorage.setItem('estadoChat', 'Chat')
                                if (datosChannel.message.botones.length > 0) {
                                    setHayBotones(true)
                                    var botones = []
                                    var botonesFinalizar = []
                                    datosChannel.message.botones.forEach(boton => {
                                        if (boton === 'Regresar' || boton === 'Finalizar') {

                                            botonesFinalizar.push(boton)

                                        } else {
                                            botones.push(boton)
                                        }
                                    })
                                    setBotones(botones)
                                    setBotonesDeFinalizacion(botonesFinalizar)
                                    localStorage.setItem('botones', JSON.stringify(botones))
                                    localStorage.setItem('botonesFin', JSON.stringify(botonesFinalizar))
                                    localStorage.setItem('hayBotones', true)
                                } else {
                                    setHayBotones(false)
                                    setBotones([])
                                    setBotonesDeFinalizacion([])
                                    localStorage.setItem('hayBotones', false)
                                }

                                if (datosChannel.message.mostrarEncuesta) {
                                    localStorage.setItem('mostrarEncuesta', true)
                                }
                                var datosJSON = JSON.parse(localStorage.getItem("mensajes")) === null ? [] : JSON.parse(localStorage.getItem("mensajes"))
                                setMessageSend([{ content: datosChannel.message.text, isMyMessage: false, tipo: 'text', timestamp: datosChannel.message.timestamp }, ...datosJSON])

                            }

                        }
                        setReceiveMsg(false)
                    });
                    channel.bind('terminate', async function (datosTerminate) {
                        var chatId = localStorage.getItem('chatId')
                        if (datosTerminate.message.correlationId === chatId) {
                            setEmpezarTimer(false)
                            setPusherActive(false)
                            if (datosTerminate.message.terminado) {
                                if (localStorage.getItem("agentePersona") === 'true') {
                                    const datosJSON = JSON.parse(localStorage.getItem("mensajes")) === null ? [] : JSON.parse(localStorage.getItem("mensajes"))
                                    const tempJSON = [{ content: 'Hasta pronto.', isMyMessage: false, tipo: 'text', timestamp: datosTerminate.message.timestamp }, ...datosJSON]
                                    setMessageSend(tempJSON)
                                }
                                await sleep(4000)
                                if (localStorage.getItem("mostrarEncuesta") === 'true') {
                                    setWillSound(true)
                                    setHayBotones(false)
                                    setAgentePersona(false)
                                    setBotones([])
                                    setBotonesDeFinalizacion([])
                                    setFormularioLlenado('Encuesta')
                                    localStorage.setItem('estadoChat', 'Encuesta')
                                    setMessageSend([])
                                    localStorage.removeItem('intervaloFranco')
                                    localStorage.removeItem('EmpezarTimer')
                                } else {
                                    setWillSound(true)
                                    setHayBotones(false)
                                    setAgentePersona(false)
                                    setBotones([])
                                    setBotonesDeFinalizacion([])
                                    setFormularioLlenado('Formulario')
                                    localStorage.setItem('estadoChat', 'Formulario')
                                    setMessageSend([])
                                    localStorage.removeItem('mostrarEncuesta')
                                    setFormularioLlenado('Formulario')
                                    localStorage.setItem('mensajes', JSON.stringify([]))
                                    localStorage.setItem('botones', JSON.stringify([]))
                                    localStorage.setItem('botonesFin', JSON.stringify([]))
                                    localStorage.setItem('hayBotones', false)
                                    localStorage.removeItem('urlFive9')
                                    localStorage.removeItem('chatId')
                                    localStorage.removeItem('externalId')
                                    localStorage.removeItem('farmId')
                                    localStorage.removeItem('estadoChat')
                                    localStorage.removeItem('mostrarEncuesta')
                                    localStorage.removeItem('agentePersona')
                                    localStorage.removeItem('intervaloFranco')
                                    localStorage.removeItem('EmpezarTimer')
                                }
                            }
                            pusher.disconnect();
                        }
                        setReceiveMsg(false)
                    });
                    channel.bind('typing', function (datosTyping) {
                        var chatId = localStorage.getItem('chatId')
                        if (datosTyping.message.correlationId === chatId) {
                            setEmpezarTimer(false)
                            localStorage.setItem('mostrarEncuesta', true)
                            localStorage.setItem('agentePersona', true)
                            setAgentePersona(true)

                        }
                    })

                }

            }
        }
    }, [openChat])



    return (
        //`${hayBotones ? "chat-container" : "chat-container-nobutton"}`
        <div className={`${formularioLlenado === 'Formulario' || formularioLlenado === 'Encuesta' ? 'chat-formulario' : receiveMsg && !agentePersona ?
            (hayBotones ? "chat-container" : "chat-container-nobutton") :
            (hayBotones ? "chat-container-noprogressbar" : "chat-container-nobutton-noprogressbar")}`}
            style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>
            <ChatTitle
                closeChat={closeChat}
                formularioLlenado={formularioLlenado}
                setFormularioLlenado={setFormularioLlenado}
                setReceiveMsg={setReceiveMsg} />
            {formularioLlenado === 'never' ?
                <SubirMedia messageSend={messageSend} setMessageSend={setMessageSend} setHayBotones={setHayBotones}
                    hayBotones={hayBotones} />
                : null}
            <MessageList
                formularioLlenado={formularioLlenado}
                setFormularioLlenado={setFormularioLlenado}
                messageSend={messageSend}
                setMessageSend={setMessageSend}
                setHayBotones={setHayBotones}
                setBotones={setBotones}
                setWillSound={setWillSound}
                setReceiveMsg={setReceiveMsg}
                setBotonesDeFinalizacion={setBotonesDeFinalizacion}
                setAgentePersona={setAgentePersona}
                setEmpezarTimer={setEmpezarTimer}
                setPusherActive={setPusherActive}

            />

            {hayBotones === true && formularioLlenado === 'Chat' ? <BotonesChat botones={botones}
                messageSend={messageSend}
                setMessageSend={setMessageSend}
                setHayBotones={setHayBotones}
                setReceiveMsg={setReceiveMsg}
                setFormularioLlenado={setFormularioLlenado}
                botonesDeFinalizacion={botonesDeFinalizacion}
                setBotonesDeFinalizacion={setBotonesDeFinalizacion}
                setBotones={setBotones}
                setAgentePersona={setAgentePersona}
            /> : null}

            {receiveMsg && !agentePersona ? <div id='progress-bar' style={{ padding: '0px 10px' }}><LinearProgress /></div> : null}

            {formularioLlenado === 'Chat' ? <ChatForm hayBotones={hayBotones} setHayBotones={setHayBotones}
                messageSend={messageSend}
                setMessageSend={setMessageSend}
                botones={botones}
                setReceiveMsg={setReceiveMsg}
                setFormularioLlenado={setFormularioLlenado}
                receiveMsg={receiveMsg}
                botonesDeFinalizacion={botonesDeFinalizacion}
                agentePersona={agentePersona}
                setEmpezarTimer={setEmpezarTimer}
                setBotones={setBotones}
                setBotonesDeFinalizacion={setBotonesDeFinalizacion}
                setAgentePersona={setAgentePersona} /> : null}
        </div>
    );
}
