import React, { useState } from "react";
import RemoveIcon from "@material-ui/icons/Remove";
import Fab from "@material-ui/core/Fab";
import "../../assets/styles/chat/ChatTitle.scss";
import CloseIcon from "@material-ui/icons/Close";
import ChatIcon from "@material-ui/icons/Chat";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import francoIMG from "../../assets/rostro_FRANCO.png";
/*===>*/ const doctorIMG = "https://i.ibb.co/wBGCd1r/franco.png";
export default function ChatTitle({
  closeChat,
  formularioLlenado,
  setFormularioLlenado,
  setReceiveMsg,
}) {
  const [open, setOpen] = useState(false);

  const llenarEncuesta = async () => {
    const farmId = localStorage.getItem("farmId");
    const urlFive9 = localStorage.getItem("urlFive9");
    const chatId = localStorage.getItem("chatId");
    handleClose();
    const requestSendMessage = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        farmId: `${farmId}`,
        Authorization: `Bearer-${chatId}`,
      },
    };
    var url = urlFive9 + "/" + chatId + "/terminate";
    try {
      await fetch(url, requestSendMessage)
        .then((responseSendMessage) => responseSendMessage.json())
        .then((dataSendMessage) => {});
    } catch (error5) {}
    if (localStorage.getItem("mostrarEncuesta") === "true") {
      localStorage.removeItem("intervaloFranco");
      localStorage.removeItem("EmpezarTimer");
      localStorage.setItem("estadoChat", "Encuesta");
      setFormularioLlenado("Encuesta");
      setReceiveMsg(false);
    } else {
      localStorage.setItem("estadoChat", "Formulario");
      setFormularioLlenado("Formulario");
      setReceiveMsg(false);
      localStorage.removeItem("mostrarEncuesta");
      localStorage.setItem("mensajes", JSON.stringify([]));
      localStorage.setItem("botones", JSON.stringify([]));
      localStorage.setItem("botonesFin", JSON.stringify([]));
      localStorage.setItem("hayBotones", false);
      localStorage.removeItem("estadoChat");
      localStorage.removeItem("mostrarEncuesta");
      localStorage.removeItem("agentePersona");
      localStorage.removeItem("intervaloFranco");
      localStorage.removeItem("EmpezarTimer");
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div id="chat-title">
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        style={{ zIndex: "999999999" }}
      >
        <DialogTitle id="alert-dialog-title">
          ¿Esta seguro de finalizar el chat con el agente?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={() => llenarEncuesta()}>Finalizar</Button>
        </DialogActions>
      </Dialog>
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <Fab
            style={{ color: "white", height: "35px", width: "35px" }}
            aria-label="add"
          >
            <img
              src="https://seahorse-app-tx78b.ondigitalocean.app/rostro_FRANCO_opt.webp"
              alt="franco"
              style={{ height: "35px", width: "35px", borderRadius: "100%" }}
            />
          </Fab>
          <span style={{ fontSize: "19px" }}>Franco - Colfondos S.A.</span>
        </div>

        <div>
          <div style={{ display: "flex" }}>
            <RemoveIcon onClick={closeChat} style={{ cursor: "pointer" }} />
            {formularioLlenado === "Chat" ? (
              <CloseIcon
                onClick={() => handleClickOpen()}
                style={{ cursor: "pointer" }}
              />
            ) : (
              <CloseIcon onClick={closeChat} style={{ cursor: "pointer" }} />
            )}
          </div>
        </div>
      </>
    </div>
  );
}
