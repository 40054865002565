import React from 'react';

import Message from './Message';

import '../../assets/styles/chat/MessageList.scss';
import Formulario from './Formulario'
import Encuesta from './Encuesta'
export default function MessageList({ formularioLlenado, setFormularioLlenado, messageSend, setMessageSend,
    setHayBotones, setBotones, setWillSound, setReceiveMsg, setBotonesDeFinalizacion, setAgentePersona,
    setEmpezarTimer, setPusherActive }) {


    let messageItems = null

    if (formularioLlenado === 'Formulario') {
        return <Formulario
            setFormularioLlenado={setFormularioLlenado}
            setMessageSend={setMessageSend}
            setHayBotones={setHayBotones}
            setBotones={setBotones}
            setWillSound={setWillSound}
            setReceiveMsg={setReceiveMsg}
            setBotonesDeFinalizacion={setBotonesDeFinalizacion}
            setAgentePersona={setAgentePersona}
            setEmpezarTimer={setEmpezarTimer}
            setPusherActive={setPusherActive} />

    } else if (formularioLlenado === 'Chat') {
        messageItems = messageSend.map((message, index) => {
            return <Message
                isMyMessage={message.isMyMessage}
                message={message}
                key={index} />;
        });
    } else if (formularioLlenado === 'Encuesta') {
        return <Encuesta
            setFormularioLlenado={setFormularioLlenado} />

    }

    return (
        <div id="chat-message-list">
            {messageItems}
        </div>
    );
}

