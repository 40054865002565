import React from "react";
import classNames from "classnames";
import "../../assets/styles/chat/Message.scss";
//
//
import moment from "moment";
import francoIMG from "../../assets/rostro_FRANCO.png";
/*===>*/ const doctorIMG = "https://i.ibb.co/wBGCd1r/franco.png";
/*===>*/ const documentBlueIcon =
  "https://assets-all-data.s3.amazonaws.com/assets/Icons/documentBlueIcon.png";

export default function Message({ isMyMessage, message }) {
  const messageClass = classNames("message-row", {
    "you-message": isMyMessage,
    "other-message": !isMyMessage,
  });

  const imageThumbnail = isMyMessage ? null : (
    <img
      src="https://seahorse-app-tx78b.ondigitalocean.app/rostro_FRANCO_opt.webp"
      alt="franco"
    />
  );

  const getFecha = (timestamp) => {
    var localLocale = moment(timestamp);
    moment.locale("es");
    localLocale.locale(false);
    return localLocale.format("D MMM , h:mm a");
  };
  function urlify(text) {
    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return text.match(urlRegex);
  }

  const renderText = (txt) =>
    txt.split(" ").map((part) =>
      urlify(part) ? (
        <a href={part} key={part} target="_blank" style={{ color: "blue" }}>
          {part}{" "}
        </a>
      ) : (
        part + " "
      )
    );

  return (
    <div className={messageClass}>
      <div className="message-content">
        {imageThumbnail}
        {message.tipo === "archivo" ? (
          <div className="message-text" style={{ cursor: "pointer" }}>
            <img src={documentBlueIcon} alt="" width="30" />
            <span>
              <b>{message.tipo + " " + getFecha(message.timestamp)}</b>
            </span>
          </div>
        ) : message.tipo === "imagen" ? (
          <div className="message-text" style={{ cursor: "pointer" }}>
            <img src={documentBlueIcon} alt="" width="30" />
            <span>
              <b>{message.tipo + " " + getFecha(message.timestamp)}</b>
            </span>
          </div>
        ) : message.tipo === "audio" ? (
          <div className="message-text" style={{ cursor: "pointer" }}>
            <img src={documentBlueIcon} alt="" width="30" />
            <span>
              <b>{message.tipo + " " + getFecha(message.timestamp)}</b>
            </span>
          </div>
        ) : message.tipo === "video" ? (
          <div className="message-text" style={{ cursor: "pointer" }}>
            <img src={documentBlueIcon} alt="" width="30" />
            <span>
              <b>{message.tipo + " " + getFecha(message.timestamp)}</b>
            </span>
          </div>
        ) : (
          <div className="message-text" style={{ fontSize: "14px" }}>
            {renderText(message.content)}
          </div>
        )}
        <div className="message-time">{getFecha(message.timestamp)}</div>
      </div>
    </div>
  );
}
