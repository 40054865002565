import React from 'react'
//import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
//import AttachFileIcon from '@material-ui/icons/AttachFile';
//import VolumeUpIcon from '@material-ui/icons/VolumeUp';
//import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';




export default function SubirMedia({ messageSend, setMessageSend, setHayBotones, hayBotones }) {
    /*
    const [upImg, setUpImg] = useState(null);
    const [upFile, setUpFile] = useState(null);
    const [upSound, setUpSound] = useState(null);
    const [upVideo, setUpVideo] = useState(null);
    */

    /*const onSelectImage = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => setUpImg(reader.result));
            reader.readAsDataURL(e.target.files[0]);
        }
    };
    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => setUpFile(reader.result));
            reader.readAsDataURL(e.target.files[0]);
        }
    };
    const onSelectSound = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => setUpSound(reader.result));
            reader.readAsDataURL(e.target.files[0]);
        }
    };
    const onSelectVideo = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => setUpVideo(reader.result));
            reader.readAsDataURL(e.target.files[0]);
        }
    };*/

    /*const sendMedia = async () => {
        if (hayBotones && (upFile || upImg || upSound || upVideo)) {
            if (messageSend.length === 0) {

                setMessageSend([
                    { content: 'Favor seleccione una de las opciones', isMyMessage: false, tipo: 'text', timestamp: Date.now() },
                    ...messageSend])
            } else {

                setMessageSend([messageSend[0],
                { content: 'Favor seleccione una de las opciones', isMyMessage: false, tipo: 'text', timestamp: Date.now() },
                ...messageSend])
            }

            setUpFile(null)
            document.getElementById('file').value = null;

            setUpImg(null)
            document.getElementById('image').value = null;

            setUpSound(null)
            document.getElementById('audio').value = null;


            setUpVideo(null)
            document.getElementById('video').value = null;

        } else {
            const timestamp = Date.now()
            if (upFile) {
                setMessageSend([{ isMyMessage: true, tipo: 'archivo', timestamp: timestamp }, ...messageSend])

                await sleep(1000)
                setMessageSend([{ content: 'Tú mensaje fue un archivo', isMyMessage: false, tipo: 'text', timestamp: Date.now() },
                { isMyMessage: true, tipo: 'archivo', timestamp: timestamp }, ...messageSend])
                setHayBotones(true)

                setUpFile(null)
                document.getElementById('file').value = null;

            }
            if (upImg) {
                setMessageSend([{ isMyMessage: true, tipo: 'imagen', timestamp: timestamp }, ...messageSend])

                await sleep(1000)
                setMessageSend([{ content: 'Tú mensaje fue un imagen', isMyMessage: false, tipo: 'text', timestamp: Date.now() },
                { isMyMessage: true, tipo: 'imagen', timestamp: timestamp }, ...messageSend])
                setHayBotones(true)

                setUpImg(null)
                document.getElementById('image').value = null;

            }
            if (upSound) {
                setMessageSend([{ isMyMessage: true, tipo: 'audio', timestamp: timestamp }, ...messageSend])

                await sleep(1000)
                setMessageSend([{ content: 'Tú mensaje fue un audio', isMyMessage: false, tipo: 'text', timestamp: Date.now() },
                { isMyMessage: true, tipo: 'audio', timestamp: timestamp }, ...messageSend])
                setHayBotones(true)

                setUpSound(null)
                document.getElementById('audio').value = null;

            }
            if (upVideo) {

                setMessageSend([{ isMyMessage: true, tipo: 'video', timestamp: timestamp }, ...messageSend])

                await sleep(1000)
                setMessageSend([{ content: 'Tú mensaje fue un video', isMyMessage: false, tipo: 'text', timestamp: Date.now() },
                { isMyMessage: true, tipo: 'video', timestamp: timestamp }, ...messageSend])
                setHayBotones(true)

                setUpVideo(null)
                document.getElementById('video').value = null;
            }

        }

    }*/

    /*
    useEffect(() => {
        sendMedia()
    }, [upImg, upFile, upSound, upVideo])
    */


    return (
        <div id='subir-media' style={{ display: 'flex', justifyContent: 'end', padding: '0px 15px', gap: '1%', background: '#f6f8fa' }}>
            {/*<div title="Add Image">
                <input id='image' type='file' accept='.png, .jpg, .jpeg' onChange={onSelectImage} style={{ display: "none" }} />
                <label htmlFor="image" style={{ cursor: "pointer", verticalAlign: 'sub' }}>
                    <AddPhotoAlternateIcon />
                </label>
            </div>
            <div title="Add File">
                <input id='file' type='file' accept='.pdf, .doc, .docx' onChange={onSelectFile} style={{ display: "none" }} />
                <label htmlFor="file" style={{ cursor: "pointer", verticalAlign: 'sub' }}>
                    <AttachFileIcon />
                </label>
            </div>
            <div title="Add Sound">
                <input id='audio' type='file' accept="audio/*" onChange={onSelectSound} style={{ display: "none" }} />
                <label htmlFor="audio" style={{ cursor: "pointer", verticalAlign: 'sub' }}>
                    <VolumeUpIcon />
                </label>
            </div>
            <div title="Add MP4">
                <input id='video' type='file' accept="video/*" onChange={onSelectVideo} style={{ display: "none" }} />
                <label htmlFor="video" style={{ cursor: "pointer", verticalAlign: 'sub' }}>
                    <VideoLibraryIcon />
                </label>
            </div> */}
        </div>
    )

}