import React, { useState, useRef, useEffect } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { TextField } from '@material-ui/core';
import '../../assets/styles/chat/ChatShell.scss';
import Pusher from 'pusher-js'


const sleep = ms => new Promise(
    resolve => setTimeout(resolve, ms)
);
export default function Formulario({ setFormularioLlenado, setMessageSend, setHayBotones, setBotones, setWillSound, setReceiveMsg,
    setBotonesDeFinalizacion, setAgentePersona, setEmpezarTimer, setPusherActive }) {
    const [captchaValido, setCaptchaValido] = useState(false)
    const [primerNombre, setPrimerNombre] = useState('');
    const [segundoNombre, setSegundoNombre] = useState('');
    const [loading, setLoading] = useState(false)
    const [errorText, setErrorText] = useState('')
    const [error, setError] = useState(false)
    const [correo, setCorreo] = useState('');
    const captcha = useRef('')
    const onChange = () => {
        if (captcha.current.getValue()) {
            setCaptchaValido(true)
        }
    }
    const submit = async (e) => {
        e.preventDefault()
        setError(false)
        setErrorText('')
        if (captchaValido) {
            setLoading(true)
            const request = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    "tenantName": "Atento - Colfondos"
                })
            }
            await fetch("https://app.five9.com/appsvcs/rs/svc/auth/anon?cookieless=true", request)
                .then(response => response.json())
                .then(async data => {
                    const jsonMetada = {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'farmId': `${data.context.farmId}`,
                            'Authorization': `Bearer-${data.tokenId}`
                        }
                    }
                    await fetch("https://" + data.metadata.dataCenters[0].uiUrls[0].host + "/appsvcs/rs/svc/auth/metadata", jsonMetada)
                        .then(responseMetadata => responseMetadata.json())
                        .then(async dataMetadata => {
                            //var idUrl = uniqueId()
                            var callBackURL = "https://urchin-app-ljrjh.ondigitalocean.app/api/chat"
                            //var callBackURL = "https://dc76-190-242-26-116.ngrok.io/api/chat"
                            const jsonCreateConver = {
                                "campaignName": "Franco - Web Messenger",
                                "tenantId": `${data.orgId}`,
                                "externalId": `${primerNombre}${segundoNombre}`,
                                "type": "CHAT",
                                "contact": {
                                    "firstName": `${primerNombre}`.toLocaleLowerCase(),
                                    "lastName": `${segundoNombre}`.toLocaleLowerCase(),
                                    "email": `${correo ? correo : primerNombre + segundoNombre + '@core.com'}`.toLocaleLowerCase().replace(/\s/g, '')
                                },
                                "priority": 10,
                                "useBusinessHours": true,
                                "callbackUrl": `${callBackURL}`,
                                "atributes": {
                                    "Question": "Probanding"
                                }
                            }
                            const requestCreateConver = {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'farmId': `${data.context.farmId}`,
                                    'Authorization': `Bearer-${data.tokenId}`
                                },
                                body: JSON.stringify(jsonCreateConver)
                            }
                            var url = "https://" + data.metadata.dataCenters[0].uiUrls[0].host + "/appsvcs/rs/svc/conversations"
                            await fetch(url, requestCreateConver)
                                .then(responseCreateConver => responseCreateConver.json())
                                .then(dataCreateConver => {
                                    localStorage.setItem('urlFive9', url)
                                    localStorage.setItem('chatId', dataCreateConver.id)
                                    localStorage.setItem('externalId', `${primerNombre}${segundoNombre}`)
                                    localStorage.setItem('farmId', `${data.context.farmId}`)
                                    const mychannel = dataCreateConver.id + '-my-channel'

                                    var pusher = new Pusher('00dc67b9864017594a92', {
                                        cluster: 'us2',
                                        encrypted: true
                                    });

                                    setPusherActive(true)

                                    var channel = pusher.subscribe(mychannel);
                                    channel.bind('message', async function (datosChannel) {
                                        var chatId = localStorage.getItem('chatId')
                                        if (datosChannel.message.correlationId === chatId) {
                                            const datosJSON = JSON.parse(localStorage.getItem("mensajes")) === null ? [] : JSON.parse(localStorage.getItem("mensajes"))

                                            if (datosChannel.message.text === 'En COLFONDOS nos importas tu, por eso pensando en tu seguridad hemos enviado un código de acceso seguro a tu número celular, por favor digítalo para acceder a nuestros servicios.' ||
                                                datosChannel.message.text === 'El código ingresado no concuerda con el código enviado. Por favor inténtalo de nuevo.') {
                                                setEmpezarTimer(true)
                                                localStorage.setItem('EmpezarTimer', true)
                                            } else {
                                                setEmpezarTimer(false)
                                                localStorage.removeItem('EmpezarTimer')
                                            }
                                            if (datosChannel.message.text === 'No agents are currently available') {

                                                setError(true)
                                                setErrorText('No hay agentes disponibles, intente nuevamente más tarde.')
                                            } else {
                                                const tempJSON = [{ content: datosChannel.message.text, isMyMessage: false, tipo: 'text', timestamp: datosChannel.message.timestamp }, ...datosJSON]

                                                setWillSound(true)
                                                setFormularioLlenado('Chat')
                                                localStorage.setItem('estadoChat', 'Chat')

                                                if (datosChannel.message.botones.length > 0) {

                                                    setHayBotones(true)
                                                    //setBotonesDeFinalizacion
                                                    var botones = []
                                                    var botonesFinalizar = []
                                                    datosChannel.message.botones.forEach(boton => {
                                                        if (boton === 'Regresar' || boton === 'Finalizar') {

                                                            botonesFinalizar.push(boton)

                                                        } else {
                                                            botones.push(boton)
                                                        }
                                                    })

                                                    setBotones(botones)
                                                    setBotonesDeFinalizacion(botonesFinalizar)
                                                    localStorage.setItem('botones', JSON.stringify(botones))
                                                    localStorage.setItem('botonesFin', JSON.stringify(botonesFinalizar))
                                                    localStorage.setItem('hayBotones', true)
                                                } else {
                                                    setHayBotones(false)
                                                    setBotones([])
                                                    setBotonesDeFinalizacion([])
                                                    localStorage.setItem('hayBotones', false)
                                                }
                                                if (datosChannel.message.mostrarEncuesta) {
                                                    localStorage.setItem('mostrarEncuesta', true)
                                                }
                                                setMessageSend(tempJSON)
                                                setError(false)
                                                setErrorText('')
                                                setCaptchaValido(false)
                                                captcha.current = null
                                                var urlFive9 = localStorage.getItem('urlFive9')
                                                var farmId = localStorage.getItem('farmId')
                                                var chatIdAcknowledge = localStorage.getItem('chatId')
                                                const msg = datosChannel.message.messageId.toString()
                                                if (msg.includes('agent')) {
                                                    const jsonAcknowledge = {
                                                        'messages': [
                                                            {
                                                                'type': 'DELIVERED',
                                                                'messageId': datosChannel.message.messageId
                                                            }
                                                        ]
                                                    }
                                                    const requestAcknowledge = {
                                                        method: 'PUT',
                                                        headers: {
                                                            'Content-Type': 'application/json',
                                                            'farmId': `${farmId}`,
                                                            'Authorization': `Bearer-${chatIdAcknowledge}`
                                                        },
                                                        body: JSON.stringify(jsonAcknowledge)
                                                    }
                                                    var urlRequestAcknowledge = urlFive9 + '/' + chatId + '/messages/acknowledge'
                                                    try {
                                                        await fetch(urlRequestAcknowledge, requestAcknowledge)
                                                            .then(responseAcknowledge => responseAcknowledge.json())
                                                            .then(dataSendAcknowledge => {
                                                            }).catch(errormute => { const mute = errormute })

                                                    } catch (error2) {
                                                        const muteer = error2

                                                    }
                                                }
                                            }
                                        }
                                        setReceiveMsg(false)
                                        setLoading(false)
                                    });
                                    channel.bind('terminate', async function (datosTerminate) {
                                        var chatId = localStorage.getItem('chatId')
                                        if (datosTerminate.message.correlationId === chatId) {
                                            setEmpezarTimer(false)
                                            setPusherActive(false)
                                            if (datosTerminate.message.terminado) {
                                                if (localStorage.getItem("agentePersona") === 'true') {
                                                    const datosJSON = JSON.parse(localStorage.getItem("mensajes")) === null ? [] : JSON.parse(localStorage.getItem("mensajes"))
                                                    const tempJSON = [{ content: 'Hasta pronto.', isMyMessage: false, tipo: 'text', timestamp: datosTerminate.message.timestamp }, ...datosJSON]
                                                    setMessageSend(tempJSON)
                                                }
                                                await sleep(4000)
                                                if (localStorage.getItem("mostrarEncuesta") === 'true') {
                                                    setWillSound(true)
                                                    setHayBotones(false)
                                                    setAgentePersona(false)
                                                    setBotones([])
                                                    setBotonesDeFinalizacion([])
                                                    setFormularioLlenado('Encuesta')
                                                    localStorage.setItem('estadoChat', 'Encuesta')
                                                    setMessageSend([])
                                                    localStorage.removeItem('intervaloFranco')
                                                    localStorage.removeItem('EmpezarTimer')
                                                } else {
                                                    setWillSound(true)
                                                    setHayBotones(false)
                                                    setAgentePersona(false)
                                                    setBotones([])
                                                    setBotonesDeFinalizacion([])
                                                    localStorage.setItem('estadoChat', 'Formulario')
                                                    setMessageSend([])
                                                    localStorage.removeItem('mostrarEncuesta')
                                                    setFormularioLlenado('Formulario')
                                                    localStorage.setItem('mensajes', JSON.stringify([]))
                                                    localStorage.setItem('botones', JSON.stringify([]))
                                                    localStorage.setItem('botonesFin', JSON.stringify([]))
                                                    localStorage.setItem('hayBotones', false)
                                                    localStorage.removeItem('urlFive9')
                                                    localStorage.removeItem('chatId')
                                                    localStorage.removeItem('externalId')
                                                    localStorage.removeItem('farmId')
                                                    localStorage.removeItem('estadoChat')
                                                    localStorage.removeItem('mostrarEncuesta')
                                                    localStorage.removeItem('agentePersona')
                                                    localStorage.removeItem('intervaloFranco')
                                                    localStorage.removeItem('EmpezarTimer')
                                                }
                                            }
                                            pusher.disconnect();
                                        }
                                        setReceiveMsg(false)
                                        setLoading(false)
                                    });
                                    channel.bind('typing', function (datosTyping) {
                                        var chatId = localStorage.getItem('chatId')
                                        if (datosTyping.message.correlationId === chatId) {
                                            setEmpezarTimer(false)
                                            localStorage.setItem('mostrarEncuesta', true)
                                            localStorage.setItem('agentePersona', true)
                                            setAgentePersona(true)
                                        }
                                    })

                                })
                        })
                })
        } else {
            captcha.current.reset()
            setError(true)
            setErrorText('Favor llenar el captcha')
        }
    }
    const onChangePrimerNombre = (event) => {
        setPrimerNombre(event.target.value)
    }

    const onChangeSegundoNombre = (event) => {
        setSegundoNombre(event.target.value)
    }

    const onChangeCorreo = (event) => {
        setCorreo(event.target.value)
    }
    useEffect(() => {
        captcha.current.reset()

    }, [])
    return (
        <div style={{ textAlign: 'center', overflowY: 'auto', padding: '0px 30px' }}>
            <div style={{ padding: '5px', fontSize: '14px' }}><span style={{ color: '#173278' }}><b>¡Hola, Soy Franco tu asistente virtual!</b></span></div>
            <div style={{ fontSize: '14px' }}><span>Por este medio podrás acceder a consultas de manera rápida y sencilla sobre los certificados y saldos de tus productos</span></div>
            <div style={{ padding: '5px', fontSize: '14px' }}><span style={{ color: '#173278' }}><b>Ingresa tus datos por favor:</b></span></div>
            <form onSubmit={submit} style={{ padding: '5px 10px' }}>
                <div>
                    <TextField
                        required
                        fullWidth
                        label='Primer Nombre'
                        name='primerNombre'
                        inputProps={{ style: { fontSize: 14 } }}
                        InputLabelProps={{ style: { fontSize: 14 } }}
                        value={primerNombre}
                        onChange={onChangePrimerNombre}
                    />
                </div>
                <div>
                    <TextField
                        required
                        fullWidth
                        label='Primer Apellido'
                        name='segundoNombre'
                        inputProps={{ style: { fontSize: 14 } }}
                        InputLabelProps={{ style: { fontSize: 14 } }}
                        value={segundoNombre}
                        onChange={onChangeSegundoNombre}
                    />
                </div>
                <div>
                    <TextField
                        fullWidth
                        label='Correo'
                        name='correo'
                        inputProps={{ style: { fontSize: 14 } }}
                        InputLabelProps={{ style: { fontSize: 14 } }}
                        value={correo}
                        onChange={onChangeCorreo}
                    />
                </div>
                <div className="divCaptcha">
                    <ReCAPTCHA
                        id='g-recaptcha'
                        ref={captcha}
                        sitekey='6LdwajEjAAAAAIFj_4Fs_A7CdCCbjhKZ-m8a1WMG'
                        onChange={onChange}
                    />
                </div>

                <div style={{ padding: '5px', fontSize: '14px' }}><span>Para atención con representante, nuestro horario de atención es de lunes a viernes de 7:30 a.m. a 6:00 p.m. en jornada continua y
                    sábados de 8:00 a.m a 1:00 p.m</span></div>


                <button disabled={loading} style={{ background: '#e22943', color: 'white', padding: '10px 30px', borderRadius: '5px', border: 'none', cursor: 'pointer' }}>
                    ¡Enviar!
                </button>
                {loading ? <div style={{ padding: '5px', fontSize: '11px', color: '#173278', fontWeight: 'bold' }}><span>Estamos conectándote con Franco...</span></div> : null}

                {error ? <div style={{ padding: '5px', fontSize: '10px', color: 'red' }}><span>{errorText}</span></div> : null}
            </form>
        </div>
    )

}