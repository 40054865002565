import React from 'react'

export default function BotonesChat({ botones, messageSend, setMessageSend, setHayBotones, setReceiveMsg, setFormularioLlenado,
    botonesDeFinalizacion, setBotonesDeFinalizacion, setBotones, setAgentePersona }) {

    const botonClick = async (data) => {
        const timestamp = Date.now()
        setMessageSend([{ content: data, isMyMessage: true, tipo: 'texto', timestamp: timestamp }, ...messageSend])
        setReceiveMsg(true)
        setHayBotones(false)

        var farmId = localStorage.getItem('farmId')
        var urlFive9 = localStorage.getItem('urlFive9')
        var chatId = localStorage.getItem('chatId')
        var externalId = localStorage.getItem('externalId')
        let jsonSendMessage = {
            "messageType": "TEXT",
            "message": `${data}`,
            "externalId": `${externalId}`
        };
        const requestSendMessage = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'farmId': `${farmId}`,
                'Authorization': `Bearer-${chatId}`
            },
            body: JSON.stringify(jsonSendMessage)
        };
        var url = urlFive9 + '/' + chatId + '/messages';
        try {
            await fetch(url, requestSendMessage)
                .then(responseSendMessage => responseSendMessage.json())
                .then(dataSendMessage => {
                    if (dataSendMessage.five9ExceptionDetail.message === 'Conversation has been already terminated') {
                        setFormularioLlenado('Formulario')
                        localStorage.setItem('estadoChat', 'Formulario')
                        setReceiveMsg(false)
                    }
                }).catch(errorMsg => {
                    if (errorMsg.message !== 'Unexpected end of JSON input') {
                        /*setHayBotones(false)
                        setAgentePersona(false)
                        setBotones([])
                        setBotonesDeFinalizacion([])
                        localStorage.setItem('estadoChat', 'Formulario')
                        setMessageSend([])
                        localStorage.removeItem('mostrarEncuesta')
                        setFormularioLlenado('Formulario')
                        localStorage.setItem('mensajes', JSON.stringify([]))
                        localStorage.setItem('botones', JSON.stringify([]))
                        localStorage.setItem('botonesFin', JSON.stringify([]))
                        localStorage.setItem('hayBotones', false)
                        localStorage.removeItem('urlFive9')
                        localStorage.removeItem('chatId')
                        localStorage.removeItem('externalId')
                        localStorage.removeItem('farmId')
                        localStorage.removeItem('estadoChat')
                        localStorage.removeItem('mostrarEncuesta')
                        localStorage.removeItem('agentePersona')
                        localStorage.removeItem('intervaloFranco')
                        localStorage.removeItem('EmpezarTimer')*/
                    }

                });
        } catch (error1) {

        }


        //setHayBotones(false)
        //await sleep(1000)
        // setMessageSend([{ content: 'Seleccionaste ' + data, isMyMessage: false, tipo: 'texto', timestamp: Date.now() },
        // { content: data, isMyMessage: true, tipo: 'texto', timestamp: timestamp }, ...messageSend])

        /*const request = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'}
            body: JSON.stringify({propertys})
        }*/
        //fetch(/*URL, JSON*/).then(response => {

        //})
    }

    return (
        <div id='chat-button'>
            <div style={{ display: 'grid', gridTemplateColumns: '50% auto', gap: '2%', padding: '10px 30px' }}>
                {botones?.map(data => {
                    return <div key={data}><button onClick={() => botonClick(data)}>{data}</button></div>
                })}

            </div>
            <div style={{ display: 'grid', gridTemplateColumns: '50% auto', gap: '2%', padding: '0px 30px' }}>

                {botonesDeFinalizacion?.map(data => {
                    return <div key={data}><button onClick={() => botonClick(data)}>{data}</button></div>
                })}
            </div>
        </div>
    )

}
