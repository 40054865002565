import React, { useState, useEffect } from "react";
import Fab from "@material-ui/core/Fab";
import ChatShell from "./ChatShell";
import chat_notification from "../../assets/sounds/bep.wav";
import "../../assets/styles/chat/Chat.scss";
import francoIMG from "../../assets/rostro_FRANCO.png";

/*===>*/ const doctorIMG = "https://i.ibb.co/wBGCd1r/franco.png";

export default function Chat() {
  const [openChat, setOpenChat] = useState(false);
  const [willSound, setWillSound] = useState(false);
  useEffect(() => {
    if (willSound) {
      if (!openChat) {
        const _audio = new Audio(chat_notification);
        const playPromise = _audio.play();
        if (playPromise !== undefined) {
          playPromise
            .then((_) => {})
            .catch((error) => {
              // Auto-play was prevented
              // Show paused UI.
            });
        }
      }
      setWillSound(false);
    }
  }, [willSound]);

  return (
    <div className="chat">
      <div style={{ display: openChat ? "block" : "none" }}>
        <ChatShell
          closeChat={() => setOpenChat(false)}
          openChat={openChat}
          setWillSound={setWillSound}
        />
      </div>
      {openChat ? null : (
        <Fab
          style={{
            color: "white",
            height: "55px",
            width: "55px",
            zIndex: "999999999",
          }}
          aria-label="add"
          onClick={() => setOpenChat(true)}
        >
          <img
            src="https://seahorse-app-tx78b.ondigitalocean.app/rostro_FRANCO_opt.webp"
            alt="franco"
            style={{ height: "55px", width: "55px", borderRadius: "100%" }}
          />
        </Fab>
      )}
    </div>
  );
}
