import logo from './logo.svg';
import './App.css';

import Chat from './components/Chat/Chat'
import React from 'react';

function App() {
  return (
    <Chat />
  );
}

export default App;
