import React, { useState } from 'react'
import { TextField } from '@material-ui/core';
import LinearProgress from "@mui/material/LinearProgress";
import moment from 'moment'


export default function Encuesta({ setFormularioLlenado }) {
    let radioButton = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    let radioButtonSatisfaccion = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    const [satisfaccion, setSatisfaccion] = useState(null)
    const [recomendar, setRecomendar] = useState(null)
    const [observacion, setObservacion] = useState('');
    const [contCara, setContCara] = useState(0)
    const [errorText, setErrorText] = useState('')
    const [error, setError] = useState(false)
    const onChangeSatisfaccion = (e) => {
        setSatisfaccion(e.target.value)
    }
    const onChangeRecomendar = (e) => {
        setRecomendar(e.target.value)
    }
    const onChangeObservacion = (event) => {
        setObservacion(event.target.value)
        setContCara(event.target.value.length)
        /*if (event.target.value.length <= 200) {
        }*/
    }
    const submit = async () => {
        if (recomendar !== null && satisfaccion !== null) {
            setError(false)
            setErrorText('')
            var chatId = localStorage.getItem('chatId')
            let bogota_datetime_str = new Date().toLocaleString("en-US", { timeZone: "America/Bogota" });
            /*var nuevaObservacion = observacion.replace(/'|"/g, ``)
            nuevaObservacion = nuevaObservacion.replace(/á/g, 'a')
            nuevaObservacion = nuevaObservacion.replace(/é/g, 'e')
            nuevaObservacion = nuevaObservacion.replace(/í/g, 'i')
            nuevaObservacion = nuevaObservacion.replace(/ó/g, 'o')
            nuevaObservacion = nuevaObservacion.replace(/ú/g, 'u')*/
            const myData = {
                Id: chatId,
                Respuesta1: satisfaccion,
                Respuesta2: recomendar,
                Observacion: observacion,
                Fecha: moment(new Date(bogota_datetime_str)).format('YYYY-MM-DD HH:mm:ss')
            }
            const requestCreateConver = {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Token': 'k^TT9I$j4&^Q6JP5'
                },
                method: 'POST',
                body: JSON.stringify(myData)
            }
            var url = "https://nrha6emlz4.execute-api.us-east-2.amazonaws.com/beta/encuesta"
            await fetch(url, requestCreateConver)
                .then(function (res) {
                    setFormularioLlenado('Formulario')
                    localStorage.setItem('mensajes', JSON.stringify([]))
                    localStorage.setItem('botones', JSON.stringify([]))
                    localStorage.setItem('botonesFin', JSON.stringify([]))
                    localStorage.setItem('hayBotones', false)
                    localStorage.removeItem('urlFive9')
                    localStorage.removeItem('chatId')
                    localStorage.removeItem('externalId')
                    localStorage.removeItem('farmId')
                    localStorage.removeItem('estadoChat')
                    localStorage.removeItem('mostrarEncuesta')
                    localStorage.removeItem('agentePersona')
                })
        } else {
            setError(true)
            setErrorText('Por favor responda las dos preguntas, muchas gracias!')
        }

    }
    const preventCopyPaste = (e) => {
        e.preventDefault()

    }
    return (
        <div style={{ textAlign: 'center', overflowY: 'auto', padding: '0px 30px' }}>
            <div style={{ padding: '5px', fontSize: '14px' }}><span style={{ color: '#191970' }}><b>¡Danos tu opinión!</b></span></div>
            <span>Califica de 1 a 10, siendo 1 el más bajo y 10 el más alto.</span>
            <br /><br />
            <div style={{ padding: '5px', fontSize: '14px' }}><span style={{ color: '#191970' }}><b>¿La satisfacción con la asesoría recibida fue?</b></span></div>
            <div style={{ display: 'flex', justifyContent: 'center' }} onChange={onChangeSatisfaccion}>
                {radioButtonSatisfaccion.map(data => {
                    return <label key={data}>
                        <div style={{ fontSize: '14px' }}>{data}</div>
                        <input type="radio" value={data} name="satisfaccion" />
                    </label>
                })}
            </div>
            <br />
            <span>Califica de 0 a 10, siendo 0 el más bajo y 10 el más alto.</span>
            <br /><br />
            <div style={{ padding: '5px', fontSize: '14px' }}><span style={{ color: '#191970' }}><b>¿Estarías dispuesto(a) a recomendar a Colfondos a un amigo o familiar?</b></span></div>
            <div style={{ display: 'flex', justifyContent: 'center' }} onChange={onChangeRecomendar}>
                {radioButton.map(data => {
                    return <label key={data}>
                        <div style={{ fontSize: '14px' }}>{data}</div>
                        <input type="radio" value={data} name="recomendar" />

                    </label>
                })}
            </div>
            <br />
            <div style={{ padding: '5px 10px' }}>
                <TextField
                    id='observacion'
                    fullWidth
                    label='Observación'
                    name='observacion'
                    multiline
                    value={observacion}
                    inputProps={{ style: { fontSize: 14 }, maxLength: 200 }}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                    onChange={onChangeObservacion}
                    onPaste={(e) => preventCopyPaste(e)}
                />
                <div>
                    {contCara !== 0 ? <LinearProgress
                        className="charProgress"
                        variant="determinate"
                        value={contCara / 2}
                    /> : null}
                </div>
            </div>
            <br />
            <button style={{ background: '#f14a56', color: 'white', padding: '10px 30px', borderRadius: '5px', border: 'none', cursor: 'pointer' }}
                onClick={() => submit()}>
                ¡Enviar!
            </button>

            {error ? <div style={{ padding: '5px', fontSize: '10px', color: 'red' }}><span>{errorText}</span></div> : null}
        </div>
    )

}