import React, { useState } from 'react';
import '../../assets/styles/chat/ChatForm.scss';


export default function ChatForm({ hayBotones, setHayBotones, messageSend, setMessageSend,
    botones, setReceiveMsg, setFormularioLlenado, receiveMsg, botonesDeFinalizacion, agentePersona,
    setEmpezarTimer, setBotones, setBotonesDeFinalizacion, setAgentePersona }) {
    const [textMessage, setTextMessage] = useState('');



    const sendMessage = async () => {
        if (textMessage !== '') {
            setEmpezarTimer(false)
            localStorage.removeItem('EmpezarTimer')
            var matchButton = []
            var matchButtonFin = []
            setHayBotones(false)
            const timestamp = Date.now()
            matchButton = botones.filter(opt => opt === textMessage)
            matchButtonFin = botonesDeFinalizacion.filter(opt => opt === textMessage)
            matchButtonFin.forEach(boton => {
                matchButton.push(boton)
            })
            setTextMessage('')
            if (matchButton.length > 0) {
                setMessageSend([{ content: matchButton[0], isMyMessage: true, tipo: 'texto', timestamp: timestamp }, ...messageSend])
                setReceiveMsg(true)
                var farmId = localStorage.getItem('farmId')
                var urlFive9 = localStorage.getItem('urlFive9')
                var chatId = localStorage.getItem('chatId')
                var externalId = localStorage.getItem('externalId')
                let jsonSendMessage = {
                    messageType: 'TEXT',
                    message: matchButton[0],
                    externalId: externalId
                }
                const requestSendMessage = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'farmId': `${farmId}`,
                        'Authorization': `Bearer-${chatId}`
                    },
                    body: JSON.stringify(jsonSendMessage)
                }
                var url = urlFive9 + '/' + chatId + '/messages'
                await fetch(url, requestSendMessage)
                    .then(responseSendMessage => responseSendMessage.json())
                    .then(dataSendMessage => {
                        if (dataSendMessage.five9ExceptionDetail.message === 'Conversation has been already terminated') {

                            setFormularioLlenado('Formulario')
                            localStorage.setItem('estadoChat', 'Formulario')
                            setReceiveMsg(false)
                        }
                    }).catch(erroMs => {
                        /*setHayBotones(false)
                        setAgentePersona(false)
                        setBotones([])
                        setBotonesDeFinalizacion([])
                        localStorage.setItem('estadoChat', 'Formulario')
                        setMessageSend([])
                        localStorage.removeItem('mostrarEncuesta')
                        setFormularioLlenado('Formulario')
                        localStorage.setItem('mensajes', JSON.stringify([]))
                        localStorage.setItem('botones', JSON.stringify([]))
                        localStorage.setItem('botonesFin', JSON.stringify([]))
                        localStorage.setItem('hayBotones', false)
                        localStorage.removeItem('urlFive9')
                        localStorage.removeItem('chatId')
                        localStorage.removeItem('externalId')
                        localStorage.removeItem('farmId')
                        localStorage.removeItem('estadoChat')
                        localStorage.removeItem('mostrarEncuesta')
                        localStorage.removeItem('agentePersona')
                        localStorage.removeItem('intervaloFranco')
                        localStorage.removeItem('EmpezarTimer')*/
                    })

            } else {
                if (hayBotones) {
                    if (messageSend.length === 0) {

                        setMessageSend([
                            { content: 'Favor seleccione una de las opciones', isMyMessage: false, tipo: 'text', timestamp: Date.now() },
                            ...messageSend])
                        setHayBotones(true)
                    } else {

                        setMessageSend([messageSend[0],
                        { content: 'Favor seleccione una de las opciones', isMyMessage: false, tipo: 'text', timestamp: Date.now() },
                        ...messageSend])
                        setHayBotones(true)
                    }

                } else {
                    setMessageSend([{ content: textMessage, isMyMessage: true, tipo: 'texto', timestamp: timestamp }, ...messageSend])
                    setReceiveMsg(true)
                    var farmId = localStorage.getItem('farmId')
                    var urlFive9 = localStorage.getItem('urlFive9')
                    var chatId = localStorage.getItem('chatId')
                    var externalId = localStorage.getItem('externalId')
                    let jsonSendMessage = {
                        messageType: 'TEXT',
                        message: textMessage,
                        externalId: externalId
                    }
                    const requestSendMessage = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'farmId': `${farmId}`,
                            'Authorization': `Bearer-${chatId}`
                        },
                        body: JSON.stringify(jsonSendMessage)
                    }
                    var url = urlFive9 + '/' + chatId + '/messages'
                    try {
                        await fetch(url, requestSendMessage)
                            .then(responseSendMessage => responseSendMessage.json())
                            .then(dataSendMessage => {
                                if (dataSendMessage.five9ExceptionDetail.message === 'Conversation has been already terminated') {

                                    setFormularioLlenado('Formulario')
                                    localStorage.setItem('estadoChat', 'Formulario')
                                    setReceiveMsg(false)
                                }
                            }).catch(erroMsg => {
                                if (erroMsg.message !== 'Unexpected end of JSON input') {
                                    /*setHayBotones(false)
                                    setAgentePersona(false)
                                    setBotones([])
                                    setBotonesDeFinalizacion([])
                                    localStorage.setItem('estadoChat', 'Formulario')
                                    setMessageSend([])
                                    localStorage.removeItem('mostrarEncuesta')
                                    setFormularioLlenado('Formulario')
                                    localStorage.setItem('mensajes', JSON.stringify([]))
                                    localStorage.setItem('botones', JSON.stringify([]))
                                    localStorage.setItem('botonesFin', JSON.stringify([]))
                                    localStorage.setItem('hayBotones', false)
                                    localStorage.removeItem('urlFive9')
                                    localStorage.removeItem('chatId')
                                    localStorage.removeItem('externalId')
                                    localStorage.removeItem('farmId')
                                    localStorage.removeItem('estadoChat')
                                    localStorage.removeItem('mostrarEncuesta')
                                    localStorage.removeItem('agentePersona')
                                    localStorage.removeItem('intervaloFranco')
                                    localStorage.removeItem('EmpezarTimer')*/
                                }
                            })

                    } catch (error3) {

                    }

                }
            }

        }
    }

    const handleTextChange = (e) => {
        e.preventDefault()
    }



    return (
        <form id="form-chat" onSubmit={handleTextChange}>
            <input
                type="text"
                placeholder="Escribe un mensaje..."
                value={textMessage}
                onChange={e => setTextMessage(e.target.value)}
                disabled={hayBotones}
                style={{ fontSize: '14px' }}
            />
            {agentePersona ? <button onClick={() => sendMessage()}>Enviar</button> : hayBotones ? <button disabled={true}>Enviar</button> :
                <button disabled={receiveMsg} onClick={() => sendMessage()}>Enviar</button>
            }
        </form>
    );
}
